.arrow,
  .arrow::before {
  position: absolute;
  width: 24px;
  height: 24px;
  background: black;
  border-radius: 3px;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

*[data-popper-placement^='top'] > .arrow {
  bottom: -9px;
}

*[data-popper-placement^='bottom'] > .arrow {
  top: -9px;
}

*[data-popper-placement^='left'] > .arrow {
  right: -9px;
}

*[data-popper-placement^='right'] > .arrow {
  left: -9px;
}